/* You can add global styles to this file, and also import other style files */
@import '~@csstools/normalize.css';
@import '_variables';
@import '~keen-slider/keen-slider.min.css';

* {
  // Set box sizing
  box-sizing: border-box;
  // Prevent blue touch callout backgrounds
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

textarea,
input {
  -webkit-touch-callout: text;
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

html {
  font-size: $default-font-size;
  font-family: $default-font-family;
}

body {
  margin: 0;
  padding: 48px 0 0 0;
  background: var(--background);
  color: var(--dark);
}

input, textarea {
  font-family: $default-font-family;
}

::placeholder {
  color: rgba(#000, 0.5);
  opacity: 1;
}
:-ms-input-placeholder {
  color: rgba(#000, 0.5);
}
::-ms-input-placeholder {
  color: rgba(#000, 0.5);
}

body.error {
  padding: 15px;
  app-navigation {
    display: none;
  }
}

/**
 * Elements
 */
.button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  border-radius: var(--button-border-radius);
  font-size: 12px;
  line-height: 40px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  border: 0;
  &:disabled {
    opacity: 0.5;
  }
  &.primary {
    box-shadow: var(--primary-button-shadow);
    border: 1px solid var(--primary-button-border);
    background-color: var(--primary-button-bg);
    color: var(--primary-button-text);
  }
  &.accent {
    box-shadow: var(--accent-button-shadow);
    border: 1px solid var(--accent-button-border);
    background-color: var(--accent-button-bg);
    color: var(--accent-button-text);
  }
  &.action {
    box-shadow: var(--action-button-shadow);
    border: 1px solid var(--action-button-border);
    background-color: var(--action-button-bg);
    color: var(--action-button-text);
  }
  &.secondary {
    color: var(--secondary-button-text);
    background: var(--secondary-button-bg);
    box-shadow: var(--secondary-button-shadow);
    border: 1px solid var(--secondary-button-border);
  }
  &.confirmation {
    color: var(--confirmation-button-text);
    background: var(--confirmation-button-bg);
    box-shadow: var(--confirmation-button-shadow);
    border: 1px solid var(--confirmation-button-border);
  }
  &.negative {
    color: var(--negative-button-text);
    background: var(--negative-button-bg);
    box-shadow: var(--negative-button-shadow);
    border: 1px solid var(--negative-button-border);
  }
  &.positive {
    color: var(--positive-button-text);
    background: var(--positive-button-bg);
    box-shadow: var(--positive-button-shadow);
    border: 1px solid var(--positive-button-border);
  }
  &.warning {
    color: var(--warning-button-text);
    background: var(--warning-button-bg);
    box-shadow: var(--warning-button-shadow);
    border: 1px solid var(--warning-button-border);
  }
  &.medium {
    height: 32px;
    line-height: 32px;
    padding: 0 8px;
  }
  &.small {
    height: 24px;
    line-height: 24px;
    padding: 0 8px;
  }
}
.icon {
  line-height: 1px;
  display: inline-block;
  vertical-align: middle;
  svg {
    display: inline-block;
    vertical-align: middle;
    height: auto;
    width: auto;
    max-width: 100%;
    margin: 0 auto;
  }
  &.main {
    svg {
      circle,
      path,
      polygon {
        fill: var(--icon-main);
      }
    }
  }
  &.arrow {
    svg {
      circle,
      path,
      polygon {
        fill: var(--icon-arrow);
      }
    }
  }
  &.primary {
    svg {
      circle,
      path,
      polygon {
        fill: var(--primary);
      }
    }
  }
  &.on-primary {
    svg {
      circle,
      path,
      polygon {
        fill: var(--on-primary);
      }
    }
  }
  &.accent {
    svg {
      circle,
      path,
      polygon {
        fill: var(--accent);
      }
    }
  }
  &.on-accent {
    svg {
      circle,
      path,
      polygon {
        fill: var(--on-accent);
      }
    }
  }
  &.warning {
    svg {
      circle,
      path,
      polygon {
        fill: var(--warning);
      }
    }
  }
  &.on-warning {
    svg {
      circle,
      path,
      polygon {
        fill: var(--on-warning);
      }
    }
  }
  &.muted {
    svg {
      circle,
      path,
      polygon {
        fill: var(--muted);
      }
    }
  }
}
.request-type-indicator {
  position: absolute;
  height: 60px;
  width: 60px;
  background: var(--background);
  transform: rotate(45deg);
  text-align: center;
  line-height: 40px;
  top: -30px;
  right: -30px;
  .icon {
    transform: rotate(-45deg);
    position: absolute;
    top: 40px;
    right: 21px;
    svg {
      path {
        fill: #ccc;
      }
    }
  }
}
.no-data-indicator {
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  margin: 0 8px 15px;
  padding: 32px 12px;
  background: var(--white);
  border: 1px solid var(--grey);
  border-top: 0;
  border-radius: 0 0 4px 4px;
  z-index: 0;
  overflow: hidden;
}
.confirm-box {
  p {
    margin: 32px 17px;
    font-size: 14px;
    line-height: 20px;
  }
  .actions {
    display: flex;
    background: var(--white);
    justify-content: space-between;
    padding: 0 0 15px;
    a {
      flex: 1;
      margin: 0 15px 0 0;
      &:last-child {
        margin: 0;
      }
    }
  }
}
.card {
  display: flex;
  justify-content: space-between;
  background: var(--white);
  border-radius: var(--card-border-radius);
  border: 1px solid var(--grey);
  margin: 0 0 16px;
  box-shadow: var(--card-shadow);
  &.no-header {
    margin-bottom: 0;
  }
  &.no-bg {
    background: transparent;
    display: block;
    .shift-header .content {
      padding: 16px;
    }
  }
  header {
    border-bottom: 1px solid var(--grey);
    padding: 12px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    h3, span {
      line-height: 24px;
    }
    h3 {
      margin: 0;
      font-size: 16px;
      font-weight: bold;
      .icon {
        vertical-align: middle;
        margin: 0 7px 0 0;
        display: inline-block;
      }
    }
    span {
      .icon {
        margin-left: 7px;
      }
    }
    .status {
      color: var(--grey-dark);
      font-size: 10px;
      font-weight: bold;
      text-transform: uppercase;
      .indicator {
        display: inline-block;
        width: 12px;
        height: 12px;
        border-radius: 6px;
        border: 1px dashed var(--grey-dark);
        margin: 0 8px -2px 0px;
        &.conceptapproved,
        &.processing {
          border: 1px solid var(--grey-dark);
        }
        &.accepted,
        &.approved {
          border: 1px solid var(--positive);
          background: var(--positive);
        }
        &.notpossible,
        &.declined {
          border: 1px solid var(--negative);
          background: var(--negative);
        }
        &.overstaffed {
          border: 1px solid var(--warning);
          background: var(--warning);
        }
        &.semi-overstaffed {
          border: 1px solid var(--warning);
          background: var(--white);
        }
      }
    }
  }
  main {
    width: 100%;
    padding: 16px 12px;
    .type {
      color: var(--primary);
      font-weight: bold;
    }
    p {
      font-size: 14px;
      line-height: 20px;
      margin: 0 0 12px;
      &:last-child {
        margin: 0;
      }
    }
    .text {
      padding: 0 30px 0 0;
    }
    .text + .tools {
      margin-top: 12px;
    }
  }
  .actions {
    padding: 16px;
    display: flex;
    align-items: center;
  }
}
.shift-header {
  display: flex;
  align-items: center;
  .content {
    padding: 16px 0 16px 16px;
    display: flex;
    align-items: center;
    flex: 1;
    .date {
      padding: 4px 12px 4px 0;
      margin: 0 8px 0 0;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      text-transform: uppercase;
      position: relative;
      &:after {
        content: "";
        display: block;
        width: 4px;
        border-radius: 2px;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        background: var(--primary);
      }
      span {
        &:first-child {
          font-weight: bold;
          margin: 0 0 4px;
        }
        display: block;
        &.changed {
          text-decoration: line-through;
        }
      }
    }
    .shift {
      h3, p {
        color: var(--dark);
        margin: 0;
      }
      h3 {
        font-weight: bold;
        opacity: 1;
        font-size: 16px;
        line-height: 19px;
        margin: 0 0 4px;
      }
      p {
        opacity: 0.7;
        font-size: 12px;
        line-height: 19px;
      }
    }
    .no-shift {
      text-align: center;
      padding-right: 16px;
      flex: 1;
      font-size: 12px;
      line-height: 19px;
      .icon {
        margin-right: 8px;
      }
    }
  }
  .tools {
    padding: 16px;
  }
  &.pending-duty {
    .content {
      .date {
        &:after {
          background: var(--white);
          -webkit-box-shadow:inset 0px 0px 0px 1px var(--accent);
          -moz-box-shadow:inset 0px 0px 0px 1px var(--accent);
          box-shadow:inset 0px 0px 0px 1px var(--accent);
        }
      }
    }
  }
  &.overstaffed {
    .content {
      .date::after {
        background: var(--warning);
      }
    }
  }
  &.alternative {
    .content {
      .date::after {
        background: var(--positive);
      }
    }
  }
  &.semi-overstaffed {
    .content {
      .date::after {
        background: var(--white);
        -webkit-box-shadow:inset 0px 0px 0px 1px var(--warning);
        -moz-box-shadow:inset 0px 0px 0px 1px var(--warning);
        box-shadow:inset 0px 0px 0px 1px var(--warning);
      }
    }
  }
  &.realized {
    .content .date::after {
      background: var(--accent);
    }
  }
  &.declined {
    .content .date::after {
      background: var(--negative);
    }
  }
}
.timeline {
  position: relative;
  padding: 17px 0 0 0;
  margin: 0 0 16px;
  &::before {
    content: "";
    position: absolute;
    z-index: 0;
    display: block;
    background: var(--grey);
    width: 2px;
    top: 0;
    bottom: 0;
    left: 15px;
  }
  .item {
    display: block;
    position: relative;
    z-index: 1;
    padding: 0 16px 24px 32px;
    &:last-child {
      padding-bottom: 0;
    }
    > h3 {
      display: flex;
      align-items: center;
      margin: 0 0 16px 0;
      font-size: 16px;
      line-height: 19px;
      span {
        flex: 1;
      }
    }
    &::before {
      content: "";
      position: absolute;
      display: block;
      background: var(--white);
      border: 1px solid var(--grey);
      width: 14px;
      height: 14px;
      border-radius: 100%;
      left: 8px;
      top: 1px;
    }
    &.active {
      &::before {
        background: var(--accent);
      }
    }
    &.secondary {
      &::before {
        background: var(--grey);
      }
    }
    .card {
      &:last-child {
        margin: 0;
      }
    }
    .requests {
      margin: 0 0 16px;
    }
  }
}
nav.header {
  background: var(--top-nav);
  color: var(--on-top-nav);
  top: 48px;
  height: 48px;
  line-height: 48px;
  box-shadow: var(--top-nav-shadow);
  text-align: center;
  display: flex;
  text-transform: uppercase;
  position: fixed;
  width: 100vw;
  z-index: 500;
  &.sub {
    background: var(--grey-light);
    color: var(--dark);
    box-shadow: none;
    z-index: 10;
    border-bottom: 1px solid var(--grey);
    button {
      .icon {
        background: transparent;
        border-color: transparent;
        svg {
          path,
          polygon {
            fill: var(--dark);
          }
        }
      }
    }
  }
  button {
    flex-basis: 60px;
    background: none;
    border: 0;
    padding: 0;
    color: var(--on-top-nav);
    font-size: $default-font-size;
    display: flex;
    align-items: center;
    .icon {
      display: inline-block;
      width: 32px;
      height: 32px;
      text-align: center;
      background: var(--button-nav-background);
      border: 1px solid var(--button-nav-border);
      border-radius: 3px;
      display: flex;
      align-items: center;
      margin: 0 auto;
    }
    &:focus {
      outline: none;
    }
  }
  span {
    flex: 1;
    font-weight: bold;
    &.multiline {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      span {
        flex: 0;
        font-size: 12px;
        line-height: 14px;
        &:last-child {
          margin: 4px 0 0 0;
          color: var(--button-nav-on-inactive);
        }
      }
    }
  }
}
nav.header + * {
  margin-top: 48px;
}
.compact-shift {
  padding: 16px;
  background: var(--white);
  h3 {
    color: var(--grey-dark);
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    margin: 0;
  }
  app-shift-header {
    .shift-header {
      .content {
        padding: 8px 0 0;
      }
    }
  }
}

app-calendar-event {
  &.event-proposal {
    background: var(--white);
    border: 1px dashed var(--grey); 
    &.overstaffed,
    &.semi-overstaffed, 
    &.shift,
    &.wildcard {
      z-index: 6 !important;
    }
    &.overstaffed {
      background: var(--warning) !important;
      border: 1px solid var(--warning) !important;
    }
    &.semi-overstaffed {
      background: white !important;
      border: 1px solid var(--warning) !important;
    }
    &.shift {
      background: var(--primary);
      border: 1px solid var(--primary);
    }
    &.wildcard {
      background: var(--accent) !important;
      border: 1px solid var(--accent) !important;
    }
    &.other-department {
      background: var(--grey-dark) !important;
      border: 1px solid var(--grey-dark) !important;
    }
  }
  &.event-alternative {
    background: var(--white);
    border: 1px solid var(--positive); 
  }
  &.event-allDay {
    &.event-joker {
      border: 1px solid var(--accent);
      background: white;
    }
  }
}

app-alternatives {
  app-calendar .day.selected {
    &:before {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      border: 2px dashed var(--positive);
    }
  }
  app-calendar-event {
    &.event-proposal {
      display: none;
      &.overstaffed,
      &.semi-overstaffed,
      &.shift,
      &.wildcard {
        display: block;
      }
    }
  }
  app-duty {
    background: var(--white);
    .shift-header .content .date:after {
      background: var(--positive);
    }
  }
}

/**
 * Component overrides
 */
app-context-menu {
  .trigger {
    svg path {
      fill: var(--on-primary);
      transition: all 0.3s ease-in-out;
    }
    &.active {
      svg path {
        fill: var(--primary);
      }
    }
  }
}
app-error {
  app-slide-up-panel {
    svg path {
      fill: var(--negative) !important;
    }
  }
}
app-navigation {
  a {
    svg {
      path,
      polygon {
        fill: var(--tab-text-inactive);
      }
    }
    &.active {
      svg {
        path,
        polygon {
          fill: var(--tab-text);
        }
      }
    }
  }
}
// We put this style here cause the span is not 
// part of the component so the component styles will miss it
app-selector-input {
  span {
    color: var(--grey);
  }
}
app-shift-selector-input {
  .shift-header {
    .content {
      padding: 0;
    }
  }
  a.active {
    .shift-header {
      .content {
        .date::after {
          background: var(--on-primary);
        }
        .shift {
          h3, p {
            color: var(--on-primary);
          }
        }
      }
      &.pending-duty {
        .content {
          .date::after {
            -webkit-box-shadow:inset 0px 0px 0px 1px var(--on-primary);
            -moz-box-shadow:inset 0px 0px 0px 1px var(--on-primary);
            box-shadow:inset 0px 0px 0px 1px var(--on-primary);
            background: var(--primary);
          }
        }
      }
    }
  }
}
app-duty {
  .shift-header {
    .content {
      padding: 8px 0;
    }
  }
  &.joker {
    .icon {
      svg {
        circle,
        path,
        polygon {
          fill: var(--on-accent);
        }
      }
    }
  }
  &.shift {
    .shift-header {
      .content {
        .date::after {
          background: var(--on-primary);
        }
        .shift {
          h3, p {
            color: var(--on-primary);
          }
        }
      }
    }
  }
  &.semi-overstaffed {
    .shift-header {
      .content {
        .date::after {
          background: var(--warning);
        }
        .shift {
          h3, p {
            color: var(--dark);
          }
        }
      }
    }
    .duty .icon {
      svg {
        circle,
        path,
        polygon {
          fill: var(--warning);
        }
      }
    }
  }
  &.overstaffed {
    .shift-header {
      .content {
        .date::after {
          background: var(--on-warning);
        }
        .shift {
          h3, p {
            color: var(--on-warning);
          }
        }
      }
    }
    .duty .icon {
      svg {
        circle,
        path,
        polygon {
          fill: var(--on-warning);
        }
      }
    }
  }
  &.disabled {
    .shift-header {
      .content {
        .date::after {
          background: var(--grey);
        }
      }
    }
  }
}
app-schedule-day button.joker.active span .icon svg {
  circle,
  path,
  polygon {
    fill: var(--on-accent);
  }
}
app-slide-up-panel {
  .confirm-box {
    .actions {
      width: calc(100% + 30px);
      margin-left: -15px;
      margin-top: 0;
      border-top: 1px solid var(--grey);
      display: flex;
      background: var(--white);
      justify-content: space-between;
      padding: 15px;
      a {
        flex: 1;
        margin: 0 15px 0 0;
        &:last-child {
          margin: 0;
        }
      }
    }
  }
}
app-wildcards {
  app-slide-up-panel {
    header svg path {
      fill: var(--accent) !important;
    }
  }
  .contained-wildcards .delete {
    display: none;
  }
}
app-conflict {
  app-shift-header .shift-header .content {
    padding: 0 16px;
  }
}


/**
 * ORTEC Theme
 */
.theme-default,
.theme-324 {
  .mode-switch .icon {
    &.primary {
      svg {
        path,
        polygon {
          fill: var(--button-nav-on-active);
        }
      }
    }
    &.on-primary {
      svg {
        path,
        polygon {
          fill: var(--button-nav-on-inactive);
        }
      }
    }
  }
  .calendar .header {
    border-bottom: 1px solid var(--calendar-border);
  }
  nav button .icon.on-primary {
    svg {
      path,
      polygon {
        fill: var(--button-nav-on-inactive);
      }
    }
  }
}
app-root:empty + .app-loader {
  opacity: 1;
  display: block;
}

.app-loader {
  opacity: 0;
  display: none;
  transition: all 0.3s ease-in-out;
  .visual {
    display: block;
    text-align: center;
    position: absolute;
    background: #FFF;
    padding: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
  .app-loader-spinner {
    margin: 0 auto;
    & > div {
      width: 18px;
      height: 18px;
      margin: 0 3px;
      background-color: #0071B3;
      border-radius: 100%;
      display: inline-block;
      -webkit-animation: app-loader-spinner 1.4s infinite ease-in-out both;
      animation: app-loader-spinner 1.4s infinite ease-in-out both;
    }
    
    .bounce1 {
      -webkit-animation-delay: -0.32s;
      animation-delay: -0.32s;
    }
    
    .bounce2 {
      -webkit-animation-delay: -0.16s;
      animation-delay: -0.16s;
    }
    
    @-webkit-keyframes app-loader-spinner {
      0%, 80%, 100% { -webkit-transform: scale(0) }
      40% { -webkit-transform: scale(1.0) }
    }
    
    @keyframes app-loader-spinner {
      0%, 80%, 100% { 
        -webkit-transform: scale(0);
        transform: scale(0);
      } 40% { 
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
      }
    }
  }
}