/**
 * Typography
 */
$default-font-family: 'Roboto', sans-serif;
$default-font-size: 12px;

// Calendar variables
$calendar-month-nav-height: 48px;
$calendar-month-header-height: 32px;
$calendar-month-day-content-number-height: 24px;

@mixin form-field {
  margin: 0 0 25px;
  span.label {
    color: var(--primary);
    font-size: 10px;
    font-weight: bold;
    line-height: 12px;
    text-transform: uppercase;
    display: block;
    margin: 0 0 6px;
  }
}

@mixin form-radio {
  font-size: 14px;
  line-height: 20px;
  label {
    display: block;
    input {
      vertical-align: middle;
      display: inline-block;
      margin: -1px 8px 0 0;
      &:checked + span {
        font-weight: bold;
      }
    }
  }
}

@mixin form-select {
  select {
    width: 100%;
    border: 1px solid var(--grey);
    border-radius: 2px;
    font-size: 14px;
    line-height: 20px;
    padding: 6px 5px;
    background: var(--white);
    option { 
      padding: 0;
    }
  }
}

@mixin form-selector-input-host {
  display: block;
  &.block {
    a {
      display: block;
      width: 100%;
      text-align: left;
      padding: 8px 16px;
      margin: 0 0 16px;
    }
  }
  // TODO: [review check] add new classes to change a original component format and look?
  &.flex {
    display: flex;
    &.full-width {
      a {
        justify-content: space-between;
        display: block;
        flex-grow: 1;
        text-align: center;
      }
    }
  }
}

@mixin form-selector-input-a {
  display: inline-block;
  background: var(--selector-bg);
  padding: 5px 8px;
  margin: 0 8px 8px 0;
  font-size: 14px;
  line-height: 20px;
  color: var(--selector-text);
  border-radius: 4px;
  border: 1px solid var(--selector-border);
  transition: background 0.3s ease-in-out, border 0.3s ease-in-out, color 0.3s ease-in-out;
  text-decoration: none;
  &.selected {
    color: var(--selector-active-text);
    border-color: var(--selector-active-border);
    background: var(--selector-active-bg);
  }
}

@mixin input-time {
  padding: 8px;
  border-radius: 4px;
  background: var(--selector-bg);
}

@mixin input-checkbox {
  height: 20px;
  width: 20px;
}